import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <>
      <div className="video-container">
        <div className="overlay"></div>
        <video
          id="test"
          className="videoTag"
          src="/media/rain-water-vieo.mp4"
          autoPlay
          muted
          type="video/mp4"
        ></video>
        <div className="content-inner">
          <img
            className="chatti-logo"
            src={"/media/Chatti Logo Copper.png"}
            alt="logo"
          />
          <h2 className="head-line">Launching Soon in New York</h2>
        </div>
      </div>
      <div className="barline">
        <p>
          A venture by{" "}
          <img
            className="logo-kck"
            src="https://kappachakkakandhari.com/wp-content/uploads/2024/03/logo.png"
          ></img>
        </p>
        <p>An outlet by Klay Pot LLC</p>
        <hr style={{ width: "100%" }} />
        <b>Address and Contact Details</b>
        <p
          style={{
            margin: "4px 0",
          }}
        >
          252 W 37th St, New York, NY 10018, United States
        </p>
        <p
          style={{
            margin: "4px 0",
          }}
        >
          Ph: +1 212-994-9599
        </p>
        <p
          style={{
            margin: "4px 0",
          }}
        >
          {" "}
          Email: klaypotnyc@gmail.com
        </p>
      </div>
    </>
  );
}

export default App;
